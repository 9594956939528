import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

// Using forwardRef to pass a ref from parent to IconButton
export const OmiIconButton = React.memo(
    forwardRef<HTMLButtonElement, IconButtonProps>(({ ...REST }, ref) => {
        return (
            <IconButton
                ref={ref} // Forward the ref to the IconButton
                size="sm"
                borderRadius={3}
                {...REST}
            />
        );
    })
);
