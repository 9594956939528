import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';


type TableBodyProps = {
    mx?: string;
    maxH?: string | number;
    children: React.ReactNode;
} & BoxProps
export const OmiContainer: React.FC<TableBodyProps> = React.memo(({ maxH = '85vh', mx = '30px', children, ...REST }) => {
    return (
        <Box
            px={mx}
            maxH={maxH}
            overflowY={'scroll'} // Always consider the scrollbar in layout
            overflowX={'hidden'}
            sx={{
                '::-webkit-scrollbar': {
                    width: '4px',
                    zIndex: '999'
                },
                '::-webkit-scrollbar-track': {
                    background: 'transparent', // Hide scrollbar track when not hovered
                },
                '::-webkit-scrollbar-thumb': {
                    background: 'transparent', // Hide scrollbar thumb when not hovered
                },
                '&:hover': {
                    '::-webkit-scrollbar-thumb': {
                        background: 'gray.500', // Show scrollbar thumb on hover
                        borderRadius: '24px',
                    },
                },
            }}
            {...REST}
        >
            {children}
        </Box>
    );
});
